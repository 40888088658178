<template>
<div class="category-page container">
  <CategoryHeader
    v-if="category"
    :activeFilter="filter"
    :category="category"
    @change-filter="handleNewFilter"
    @sort-projects="sortProjects"
  />
  <h1 v-else class="category-header">
    <h1 class="category-intro">
      {{ $t(`project_filters.${filter}`) }}
    </h1>
  </h1>
  <ProjectGrid :projects="projects" />
</div>
</template>

<script>
import * as api from '@/utils/api';
import ProjectGrid from '@/components/widget/ProjectGrid.vue';
import CategoryHeader from '@/components/widget/CategoryHeader.vue';

export default {
  name: 'category',
  components: {
    ProjectGrid,
    CategoryHeader,
  },
  data() {
    return {
      categoryId: null,
      filter: 'live_projects',
      sort: 'NEWEST',
      changedFilter: '',
      projects: [],
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    category() {
      return this.$store.getters.categoryById(this.categoryId);
    },
  },
  methods: {
    handleNewFilter(value) {
      this.filter = value;
      this.getProjects();
    },
    sortProjects(sort) {
      this.sort = sort;
      this.getProjects();
    },
    async getProjects() {
      const { filter, sort, categoryId } = this;
      const { projects } = await api.getProjects({ filter, sort, categoryId });
      this.projects = projects;
    },
    async prepareData({ params }) {
      if(params.categoryId) {
        this.categoryId = params.categoryId.toString();
        await this.getProjects();
      } else {
        this.filter = params.filter.replace('-', '_');
        this.categoryId = null;
        if(this.$te(`project_filters.${this.filter}`)) {
          await this.getProjects();
        } else {
          this.$router.replace({ name: 'not-found-page' });
        }
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    await this.prepareData(to);
    next();
  },
  mounted() {
    this.prepareData(this.$route);
  },
};
</script>

<style lang="scss">
.category-page {
  min-height: 400px;

  .category-header {
    padding: 0 104px;
  }
}

@media (max-width: $tablet-width-large) {
  .category-page {
    .category-header { padding: 0 30px; }
    .project-grid {
      width: 100%;
      padding: 0 22px;
    }
  }
}

@media (max-width: $mobile-width) {
  .category-page {
    .category-header,
    .project-grid {
      padding: 0;
    }
  }
}
</style>
