<template>
<div class="project-grid">
  <ProjectItem
    v-for="project in projects"
    :key="project.id"
    :project="project"
  />
  <div
    v-for="index in 4 - (projects.length % 4)"
    :key="`hidden-${index}`"
    class="project-item project-item-phantom"
  />
</div>
</template>

<script>
import ProjectItem from '@/components/widget/ProjectItem.vue';

export default {
  name: 'project-grid',
  components: {
    ProjectItem,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.project-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .project-item {
    margin: 50px 8px;
    justify-self: center;
    width: 25%;
    height: unset;
  }
  .project-item-phantom {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: $desktop-width-small) {
  .project-grid .project-item { width: 33%; }
}

@media (max-width: $tablet-width-large) {
  .project-grid .project-item { width: 50%; }
}

@media (max-width: $mobile-width) {
  .project-grid .project-item {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 50px 0;
  }
}

</style>
